<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标签名称" clearable v-model="searchParam.nickName" />
        <v-input
          clearable
          label="手机号"
          :maxlength="11"
          v-model="searchParam.mobileNum"
        />
        <community-select v-model="searchParam.spaceId" label="选择园区" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getRelatedUserListUrl } from "./api.js";
import { createAlinkVNode } from "@/utils/utils.js";

export default {
  name: "userNumberList",
  data() {
    return {
      timer: null,
      searchParam: {
        labelId: "",
        nickName: undefined, // 昵称
        mobileNum: undefined, // 手机号
        spaceId: undefined, // 选择小区
      },
      tableUrl: getRelatedUserListUrl,
      headers: [
        {
          prop: "nickName",
          label: "昵称",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row[prop],
              cb: () => this.toUserManageForm(row),
            }),
        },
        {
          prop: "mobileNum",
          label: "手机号",
        },
        {
          prop: "sexName",
          label: "性别",
        },
        {
          prop: "houseName",
          label: "房号",
        },
      ],
    };
  },
  created() {
    const { labelId } = this.$route.query;
    this.searchParam.labelId = labelId;
    this.$setBreadList("用户人数列表");
  },
  methods: {
    toUserManageForm(row) {
      this.$router.push({
        name: "userManageForm",
        query: {
          id: row.userId,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
